import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api, { routes } from '../constants/api';
import { useAppSelector } from './useApp';

interface IJourneyDetails {
  id: number;
  name: string;
  description: string;
  audience: IAudience;
  campaigns: ICampaign[];
}

interface ICampaign {
  id: number;
  name: string;
  emails: IEmail[];
}

export interface IEmail {
  id: number;
  from: string;
  name: string;
  subject: string;
  body: string;
  runEvery: string;
  runTime: string;
  runTimePeriod: string;
  runRange: number;
  rules: IRule[];
}

export interface IRule {
  id?: number;
  when: string;
  value: string;
}

export interface IAudience {
  id: number;
  name: string;
  code: string;
}

const useData = () => {
  const { journeyId, campaignId, emailId } = useParams();
  const [activeJourney, setActiveJourney] = useState<IJourneyDetails>({
    id: 0,
    name: 'Loading...',
    description: '',
    audience: {
      id: 0,
      name: 'Loading...',
      code: ''
    },
    campaigns: []
  });
  const [activeCampaign, setActiveCampaign] = useState<ICampaign>({
    id: 0,
    name: 'Loading...',
    emails: []
  });
  const [activeEmail, setActiveEmail] = useState<IEmail>({
    id: 0,
    from: '',
    name: 'Loading...',
    subject: '',
    body: '',
    runEvery: 'days',
    runTime: '12:00',
    runTimePeriod: 'AM',
    runRange: 0,
    rules: []
  });
  const { journeys, status } = useAppSelector((state) => state.journeys);

  const getJourneyDetails = async (force: boolean = false) => {
    if (!force) {
      if (!journeyId || journeyId === activeJourney?.name) return;
    }

    const journey = journeys.find((j) => j.name === journeyId);

    if (!journey) return;

    const { data } = await api.get(routes.journeys.get(journey.id));

    setActiveJourney(data.data);
  };

  const getCampaignDetails = async (force: boolean = false) => {
    if (!force) {
      if (!campaignId || campaignId === activeCampaign?.name) return;
    }

    const campaign = activeJourney?.campaigns.find(
      (c) => c.name === campaignId
    );

    if (!campaign) return;

    const { data } = await api.get(routes.campaigns.get(campaign.id));

    setActiveCampaign(data.data);
  };

  const getEmailDetails = async (force: boolean = false) => {
    if (!force) {
      if (
        !emailId ||
        removeSpecialCharacters(emailId) ===
          removeSpecialCharacters(activeEmail?.name)
      )
        return;
    }

    console.log({ emailId });

    const email = activeCampaign?.emails.find(
      (e) =>
        removeSpecialCharacters(e.name) ===
        removeSpecialCharacters(emailId ?? '')
    );

    if (!email) return;

    const { data } = await api.get(routes.emails.get(email.id));

    setActiveEmail(data.data);
  };

  const refreshData = () => {
    getJourneyDetails(true);
    getCampaignDetails(true);
    getEmailDetails(true);
  };

  useEffect(() => {
    if (status !== 'success') return;

    getJourneyDetails();
    getCampaignDetails();
    getEmailDetails();
  }, [
    journeyId,
    campaignId,
    emailId,
    status,
    activeJourney,
    activeCampaign,
    activeEmail
  ]);

  return {
    activeJourney,
    activeCampaign,
    activeEmail,
    refreshData
  };
};

const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, '').trim();
};

export default useData;
