import React from 'react';
import './_styles.button.scss';

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  type?: 'submit' | 'button';
  secondary?: boolean;
  danger?: boolean;
  loading?: boolean;
  loadingText?: string;
  success?: boolean;
  successText?: string;
}

const Button = ({
  onClick,
  children,
  type = 'button',
  secondary,
  danger,
  loading,
  loadingText = 'Loading',
  success,
  successText = 'Success'
}: ButtonProps) => {
  return (
    <button
      className={`button ${secondary && 'secondary'} ${loading && 'loading'} ${
        success && 'success'
      } ${danger && 'danger'}`}
      onClick={onClick}
      type={type}
    >
      {loading ? loadingText : success ? successText : children}
    </button>
  );
};

export default Button;
