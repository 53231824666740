import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ListItem, Table } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { getJourneys } from '../../state/slices/journeySlice';
import './_styles.home.scss';
import api, { routes } from '../../constants/api';

const Home = () => {
  const { journeys, status } = useAppSelector((state) => state.journeys);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getJourneys());
    }
  }, [status]);

  const deleteJourney = async (id: number) => {
    const result = window.confirm(
      'Are you sure you want to delete this journey?'
    );

    if (result) {
      const { data } = await api.delete(routes.journeys.delete(id));

      if (data.success) {
        dispatch(getJourneys());
      }
    }
  };

  return (
    <div className='home'>
      <div className='page-header'>
        <h1>Journeys</h1>
        <Button onClick={() => navigate('new')}>New Journey</Button>
      </div>
      <div className='list'>
        {journeys.map((journey) => (
          <ListItem
            key={journey.id}
            navigateTo={'/' + journey.name}
            onDelete={() => deleteJourney(journey.id)}
          >
            <p className='title'>{journey.name}</p>
            <p>{journey.description}</p>
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default Home;
