import React, { useState } from 'react';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import api, { routes } from '../../constants/api';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    loading: false,
    error: ''
  });

  const handleForgot = async (e: any) => {
    e.preventDefault();
    setState({ ...state, loading: true });

    const { data } = await api.post(routes.auth.forgotPassword, {
      email: state.email
    });

    setState({ ...state, loading: false });
    if (data.success) {
      navigate('/');
    }
  };

  return (
    <div className='login'>
      <img />
      <form className='tile' onSubmit={handleForgot}>
        <p>Enter your email to reset your password</p>
        <input
          placeholder='Email'
          onChange={(e) => setState({ ...state, email: e.target.value })}
        />
        <Button type='submit' loading={state.loading}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
