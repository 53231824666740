import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api, { routes } from '../../constants/api';

const initialState = {
  token: null,
  status: 'idle',
  me: {
    id: 0,
    firstname: '',
    lastname: '',
    email: ''
  },
  authenticated: false,
  error: ''
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await api.post(routes.auth.login, { email, password });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.error);
    }
  }
);

export const authenticate = createAsyncThunk('auth/authenticate', async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token');
  }

  const response = await api.post(routes.auth.authenticate, null, {
    headers: { authorization: `Bearer ${token}` }
  });
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      state = initialState;
      localStorage.removeItem('token');
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'success';
        state.token = action.payload.token;
        state.me = action.payload.user;
        state.authenticated = true;
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? '';
      })
      .addCase(authenticate.pending, (state) => {
        state.status = 'loading-authentication';
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.me = action.payload.user;
        localStorage.setItem('token', action.payload.token);
        state.status = 'success';
        state.authenticated = true;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.status = 'failed';
      });
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
