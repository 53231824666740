import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import journeySlice from './slices/journeySlice';
import authSlice from './slices/authSlice';
import api from '../constants/api';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    journeys: journeySlice
  }
});

api.interceptors.request.use((req: any) => {
  const token = store.getState().auth.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
