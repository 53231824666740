import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ListItem } from '../../components';
import api, { routes } from '../../constants/api';
import { IAudience } from '../../hooks/useData';
import { getJourneys } from '../../state/slices/journeySlice';
import { useAppDispatch } from '../../hooks/useApp';

const Audiences = () => {
  const [audiences, setAudiences] = useState<IAudience[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getAudiences = async () => {
    const { data } = await api.get(routes.audiences.list);

    setAudiences(data.data);
  };

  useEffect(() => {
    getAudiences();
  }, []);

  const deleteAudience = async (id: number) => {
    const result = window.confirm(
      'Are you sure you want to delete this audience? This will also delete all journeys associated with this audience.'
    );

    if (result) {
      const { data } = await api.delete(routes.audiences.delete(id));

      if (data.success) {
        getAudiences();
        dispatch(getJourneys());
      }
    }
  };

  return (
    <div className='home'>
      <div className='page-header'>
        <h1>Audiences</h1>
        <Button onClick={() => navigate('new')}>New Audience</Button>
      </div>
      <div className='list'>
        {audiences.map((audience) => (
          <ListItem
            key={audience.id}
            navigateTo={audience.id.toString()}
            onDelete={() => deleteAudience(audience.id)}
          >
            <p className='title'>{audience.name}</p>
            <p>{audience.code}</p>
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default Audiences;
