import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Breadcrumbs, Header } from './components';
import { useAppDispatch } from './hooks/useApp';
import { Campaign, Email, Home, Journey, Login } from './pages';
import Audience from './pages/Audience/Audience';
import Audiences from './pages/Audiences/Audiences';
import { getJourneys } from './state/slices/journeySlice';
import { store } from './state';
import { authenticate } from './state/slices/authSlice';
import Admin from './pages/Admin/Admin';
import User from './pages/User/User';
import Activate from './pages/Activate/Activate';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = store.getState().auth;
  let location = useLocation();

  if (!auth.authenticated) {
    store.dispatch(authenticate());
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getJourneys());
  }, []);

  const isLogin =
    location.pathname.includes('login') ||
    location.pathname.includes('forgotpassword') ||
    location.pathname.includes('activate') ||
    location.pathname.includes('reset-password');

  return (
    <div className={isLogin ? '' : 'container'}>
      {!isLogin && (
        <>
          <Header />
          <Breadcrumbs />
        </>
      )}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/activate/:token' element={<Activate />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
        <Route
          path='/'
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path='/admin'
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        />
        <Route
          path='/admin/:id'
          element={
            <RequireAuth>
              <User />
            </RequireAuth>
          }
        />
        <Route
          path='/audiences'
          element={
            <RequireAuth>
              <Audiences />
            </RequireAuth>
          }
        />
        <Route
          path='/audiences/:audienceId'
          element={
            <RequireAuth>
              <Audience />
            </RequireAuth>
          }
        />
        <Route
          path='/:journeyId'
          element={
            <RequireAuth>
              <Journey />
            </RequireAuth>
          }
        />
        <Route
          path='/:journeyId/:campaignId'
          element={
            <RequireAuth>
              <Campaign />
            </RequireAuth>
          }
        />
        <Route
          path='/:journeyId/:campaignId/:emailId'
          element={
            <RequireAuth>
              <Email />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
