import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './_styles.breadcrumbs.scss';

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let paths = location.pathname.split('/');

  const root = () => {
    if (location.pathname.includes('audiences')) {
      return 'Audiences';
    }

    if (location.pathname.includes('admin')) {
      return 'Admin';
    }

    return 'Journeys';
  };

  const navigateTo = () => {
    if (location.pathname.includes('audiences')) {
      return '/audiences';
    }

    if (location.pathname.includes('admin')) {
      return '/admin';
    }

    return '/';
  };

  return (
    <div className='breadcrumbs'>
      <p>/</p>
      {paths.map((path, index) => {
        if (index == 0)
          return (
            <>
              <p key={index} onClick={() => navigate(navigateTo())}>
                {root()}
              </p>
              {location.pathname.length > 1 && <p key={index + '/'}>/</p>}
            </>
          );
        if (index > 0 && path !== 'audiences' && path !== 'admin')
          return (
            <>
              <p
                key={index}
                onClick={() =>
                  navigate(
                    location.pathname.substring(
                      0,
                      location.pathname.indexOf(path)
                    ) + path
                  )
                }
              >
                {path.replaceAll('%20', ' ')}
              </p>
              {index != paths.length - 1 && <p key={index + '/'}>/</p>}
            </>
          );
      })}
    </div>
  );
};

export default Breadcrumbs;
