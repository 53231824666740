import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, ListItem } from '../../components';
import api, { routes } from '../../constants/api';
import useData from '../../hooks/useData';

const Campaign = () => {
  const { campaignId, journeyId } = useParams();
  const navigate = useNavigate();
  const { activeJourney, activeCampaign, refreshData } = useData();
  const [formData, setFormData] = useState({
    name: '',
    journey_id: ''
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const createCampaign = async (e: any) => {
    e.preventDefault();
    const { data } = await api.post(routes.campaigns.create, {
      ...formData,
      journey_id: activeJourney?.id
    });

    if (data.success) {
      navigate(-1);
    }
  };

  const updateCampaign = async (e: any) => {
    e.preventDefault();
    const { data } = await api.put(
      routes.campaigns.update(activeCampaign?.id ?? 0),
      { name: formData.name }
    );

    if (data.success) {
      navigate(`/${activeJourney.name}/${data.data.name}`);
      refreshData();
    }
  };

  const deleteEmail = async (id: number) => {
    const result = window.confirm(
      'Are you sure you want to delete this email?'
    );

    if (result) {
      const { data } = await api.delete(routes.emails.delete(id));

      if (data.success) {
        refreshData();
      }
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      name: activeCampaign?.name ?? ''
    });
  }, [searchParams]);

  if (campaignId === 'new') {
    return (
      <div>
        <div className='page-header'>
          <h1>New Campaign</h1>
        </div>
        <form onSubmit={createCampaign}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Campaign'
              type='text'
              id='name'
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type='submit'>Create</Button>
          </div>
        </form>
      </div>
    );
  }

  if (searchParams.get('edit') === 'true') {
    return (
      <div>
        <div className='page-header'>
          <h1>Edit Campaign</h1>
        </div>
        <form onSubmit={updateCampaign}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Campaign'
              type='text'
              id='name'
              value={formData.name}
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => setSearchParams({})}>
              Cancel
            </Button>
            <Button type='submit'>Update</Button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      <div className='page-header tile'>
        <div>
          <h2>{activeJourney?.name}</h2>
          <p>
            Campaign: <strong>{activeCampaign?.name}</strong>
          </p>
          <p>
            Audience: <strong>{activeJourney?.audience.code}</strong>
          </p>
        </div>
        <Button secondary onClick={() => setSearchParams({ edit: 'true' })}>
          Edit
        </Button>
      </div>
      <div className='campaigns'>
        <div className='title'>
          <p>Emails</p>
          <Button onClick={() => navigate('new')}>New Email</Button>
        </div>
        <div className='list'>
          {activeCampaign.emails.map((email) => (
            <ListItem
              title={email.name}
              navigateTo={email.name}
              onDelete={() => deleteEmail(email.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Campaign;
