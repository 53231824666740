import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IJourney } from '../../state/slices/journeySlice';
import './_styles.journey.scss';
import Button from '../Button/Button';

interface JourneyProps {
  title?: string;
  subtitle?: string;
  navigateTo: string;
  children?: React.ReactNode;
  onDelete?: () => void;
  canDelete?: boolean;
}

const ListItem = ({
  title,
  subtitle,
  navigateTo,
  children,
  onDelete,
  canDelete = true
}: JourneyProps) => {
  const navigate = useNavigate();

  return (
    <div className='journey-item'>
      {children ? (
        <div onClick={() => navigate(`${navigateTo}`)}>{children}</div>
      ) : (
        <div onClick={() => navigate(`${navigateTo}`)}>
          <p className='title'>{title}</p>
          <p>{subtitle}</p>
        </div>
      )}
      {canDelete && onDelete && (
        <Button danger onClick={onDelete}>
          Delete
        </Button>
      )}
    </div>
  );
};

export default ListItem;
