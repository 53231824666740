import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import './_styles.login.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { login } from '../../state/slices/authSlice';

interface LocationState {
  from: string;
}

const Login = () => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { from } = (location.state as LocationState) || '/';

  const handleLogin = (e: any) => {
    e.preventDefault();

    dispatch(login(loginData));
  };

  const { status, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (status === 'success') {
      navigate(
        from === '/login' || from === '/register' || from === undefined
          ? '/'
          : from
      );
    }

    return () => {};
  }, [status, from, navigate]);

  return (
    <div className='login'>
      <img />
      <form className='tile' onSubmit={handleLogin}>
        <p>Sign in to your account</p>
        <input
          placeholder='Email'
          onChange={(e) =>
            setLoginData({ ...loginData, email: e.target.value })
          }
        />
        <input
          placeholder='Password'
          onChange={(e) =>
            setLoginData({ ...loginData, password: e.target.value })
          }
          type='password'
        />
        <p className='error'>{error}</p>
        <Button type='submit' loading={status === 'loading'}>
          Login
        </Button>
        <Link to='/forgotpassword'>Forgot your password?</Link>
      </form>
    </div>
  );
};

export default Login;
