import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, ListItem } from '../../components';
import api, { routes } from '../../constants/api';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import useData, { IAudience } from '../../hooks/useData';
import { getJourneys } from '../../state/slices/journeySlice';
import './_styles.journey.scss';
import useQuery from '../../hooks/useQuery';

const Journey = () => {
  const navigate = useNavigate();
  const { journeyId } = useParams<{ journeyId: string }>();
  const [audiences, setAudiences] = useState<IAudience[]>([]);
  const { activeJourney, refreshData } = useData();
  const [formData, setFormData] = useState({
    name: activeJourney?.name ?? '',
    description: activeJourney?.description ?? '',
    audience_id: activeJourney?.audience.id.toString() ?? ''
  });
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const createJourney = async (e: any) => {
    e.preventDefault();
    const { data } = await api.post(routes.journeys.create, formData);

    if (data.success) {
      dispatch(getJourneys());
      navigate(-1);
    }
  };

  const updateJourney = async (e: any) => {
    e.preventDefault();
    const { data } = await api.put(
      routes.journeys.update(activeJourney?.id ?? 0),
      formData
    );

    if (data.success) {
      dispatch(getJourneys());
      navigate('/' + data.data.name);
    }
  };

  const getAudiences = async () => {
    const { data } = await api.get(routes.audiences.list);

    setAudiences(data.data);

    setFormData({
      ...formData,
      audience_id: data.data[0].id
    });
  };

  const deleteCampaign = async (id: number) => {
    const result = window.confirm(
      'Are you sure you want to delete this campaign?'
    );

    if (result) {
      const { data } = await api.delete(routes.campaigns.delete(id));

      if (data.success) {
        refreshData();
      }
    }
  };

  useEffect(() => {
    getAudiences();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      name: activeJourney?.name ?? '',
      description: activeJourney?.description ?? '',
      audience_id: activeJourney?.audience.id.toString() ?? ''
    });
  }, [searchParams]);

  if (journeyId === 'new') {
    return (
      <div>
        <div className='page-header'>
          <h2>New Journey</h2>
        </div>
        <form onSubmit={createJourney}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Journey'
              type='text'
              id='name'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'>Description</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              placeholder='Description'
              type='text'
              id='description'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'>Audiences</label>
            <select
              onChange={(e) =>
                setFormData({ ...formData, audience_id: e.target.value })
              }
            >
              {audiences.map((audience) => (
                <option key={audience.id} value={audience.id}>
                  {audience.name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type='submit'>Create</Button>
          </div>
        </form>
      </div>
    );
  }

  if (searchParams.get('edit') === 'true') {
    return (
      <div>
        <div className='page-header'>
          <h2>Edit Journey</h2>
        </div>
        <form onSubmit={updateJourney}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Journey'
              type='text'
              id='name'
              value={formData?.name}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'>Description</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              placeholder='Description'
              type='text'
              id='description'
              value={formData?.description}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'>Audiences</label>
            <select
              onChange={(e) =>
                setFormData({ ...formData, audience_id: e.target.value })
              }
              value={formData?.audience_id}
            >
              {audiences.map((audience) => (
                <option key={audience.id} value={audience.id}>
                  {audience.name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => setSearchParams({})}>
              Cancel
            </Button>
            <Button type='submit'>Update</Button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      <div className='page-header tile'>
        <div>
          <h2>{activeJourney?.name}</h2>
          <p>{activeJourney?.description}</p>
          <p>
            Audience: <strong>{activeJourney?.audience.code}</strong>
          </p>
        </div>
        <Button secondary onClick={() => setSearchParams({ edit: 'true' })}>
          Edit
        </Button>
      </div>
      <div className='campaigns'>
        <div className='title'>
          <p>Campaigns</p>
          <Button onClick={() => navigate('new')}>New Campaign</Button>
        </div>
        <div className='list'>
          {activeJourney?.campaigns?.map((campaign) => (
            <ListItem
              key={campaign.id}
              title={campaign.name}
              navigateTo={campaign.name}
              onDelete={() => deleteCampaign(campaign.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Journey;
