import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  Editor,
  EditorState
} from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import './_styles.richinput.scss';

interface RichInputProps {
  defaultValue?: string;
  keys: string[];
  onChange?: (value: string) => void;
}

const RichInput = ({ keys, onChange, defaultValue = '' }: RichInputProps) => {
  const customDecorator = new CompositeDecorator([
    {
      // Define the strategy for finding the words that match the keys
      strategy: (contentBlock: ContentBlock, callback: any) => {
        // Get the text of the content block
        const text = contentBlock.getText();
        // Loop through each of the given keys
        keys.forEach((key: string) => {
          // Use a regular expression to find all instances of the key in the text starting with the @ symbol
          const regex = new RegExp(`${key}`, 'g');
          let matchArr, start;
          while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            // Call the callback function with the start and end indices of the key
            callback(start, start + key.length);
          }
        });
      },
      // Define the component that will be used to render the matched words
      component: (props: any) => <span className='key'>{props.children}</span>
    }
  ]);

  const [characterCount, setCharacterCount] = useState(0);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(defaultValue),
      customDecorator
    )
  );

  useEffect(() => {
    setCharacterCount(editorState.getCurrentContent().getPlainText().length);
    onChange && onChange(editorState.getCurrentContent().getPlainText());
  }, [editorState.getCurrentContent().getPlainText()]);

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(defaultValue),
        customDecorator
      )
    );
  }, [defaultValue]);

  return (
    <div className='rich-input'>
      <Editor editorState={editorState} onChange={setEditorState} />
      <p className='character-counter'>{characterCount} of 4000</p>
    </div>
  );
};

export default RichInput;
