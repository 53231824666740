import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './_styles.header.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { logout } from '../../state/slices/authSlice';

const Header = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { me } = useAppSelector((state) => state.auth);

  const onLogout = async () => {
    await dispatch(logout());

    window.location.pathname = '/login';
  };

  return (
    <div className='header'>
      <img />
      <Link
        to='/'
        className={
          !location.pathname.includes('audiences') &&
          !location.pathname.includes('admin')
            ? 'active'
            : 'inactive'
        }
      >
        Journeys
      </Link>
      <Link
        to='/audiences'
        className={
          location.pathname.includes('audiences') ? 'active' : 'inactive'
        }
      >
        Audiences
      </Link>
      <Link
        to='/admin'
        className={location.pathname.includes('admin') ? 'active' : 'inactive'}
      >
        Admin
      </Link>
      <div>
        <p>Hello {me?.firstname}</p>
        <p onClick={onLogout}>Logout</p>
      </div>
    </div>
  );
};

export default Header;
