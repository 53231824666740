import React, { useState } from 'react';
import { Button } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import api, { routes } from '../../constants/api';
import './_styles.activate.scss';

const Activate = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    loading: false,
    error: ''
  });

  const activate = async (e: any) => {
    e.preventDefault();
    setState({ ...state, loading: true });

    if (state.password !== state.confirmPassword) {
      setState({ ...state, loading: false, error: 'Passwords do not match' });
      return;
    }

    const { data } = await api.post(routes.auth.activate, {
      password: state.password,
      token
    });

    setState({ ...state, loading: false });
    if (data.success) {
      navigate('/');
    }
  };

  return (
    <div className='activate'>
      <img />
      <form className='tile' onSubmit={activate}>
        <p>Create your account</p>
        <input
          placeholder='Password'
          onChange={(e) => setState({ ...state, password: e.target.value })}
          type='password'
        />
        <input
          placeholder='Confirm Password'
          onChange={(e) =>
            setState({ ...state, confirmPassword: e.target.value })
          }
          type='password'
        />
        <p className='error'>{state.error}</p>
        <Button type='submit' loading={state.loading}>
          Create Account
        </Button>
      </form>
    </div>
  );
};

export default Activate;
