import React, { useEffect, useState } from 'react';
import { Button, ListItem } from '../../components';
import { useNavigate } from 'react-router-dom';
import api, { routes } from '../../constants/api';
import { useAppSelector } from '../../hooks/useApp';

interface IUser {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
}

const Admin = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>([]);
  const { me } = useAppSelector((state) => state.auth);

  const getUsers = async () => {
    const { data } = await api.get(routes.users.list);

    setUsers(data.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (id: number) => {
    const result = window.confirm('Are you sure you want to delete this user?');

    if (result) {
      const { data } = await api.delete(routes.users.delete(id));

      if (data.success) {
        getUsers();
      }
    }
  };

  return (
    <div>
      <div className='page-header'>
        <h1>Users</h1>
        <Button onClick={() => navigate('new')}>New User</Button>
      </div>
      <div className='list'>
        {users.map((user) => (
          <ListItem
            key={user.id}
            navigateTo={user.id.toString()}
            onDelete={() => deleteUser(user.id)}
            canDelete={user.id !== me.id}
          >
            <p className='title'>
              {user.firstname} {user.lastname}
            </p>
            {user.active && <p className='active'>Active</p>}
            {!user.active && <p className='inactive'>Inactive</p>}
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default Admin;
