import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api, { routes } from '../../constants/api';
import { Button } from '../../components';

const User = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: '',
    surname: '',
    email: ''
  });

  const [loading, setLoading] = useState(false);

  const isNew = id === 'new';

  const createUser = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await api.post(routes.users.create, formData);

    setLoading(false);
    if (data.success) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isNew) return;

    const fetchAudience = async () => {
      const { data } = await api.get(routes.users.get(parseInt(id ?? '')));
      setFormData(data.data);
    };

    fetchAudience();
  }, [id]);

  if (isNew) {
    return (
      <div>
        <div className='page-header'>
          <h1>New User</h1>
        </div>
        <form onSubmit={createUser}>
          <div className='form-row'>
            <div className='form-group'>
              <label htmlFor='name'>First Name</label>
              <input
                onChange={(e) =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
                placeholder='Name'
                type='text'
                id='name'
                value={formData.firstname}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='surname'>Surname</label>
              <input
                onChange={(e) =>
                  setFormData({ ...formData, surname: e.target.value })
                }
                placeholder='Surname'
                type='text'
                id='surname'
                value={formData.surname}
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              placeholder='Email'
              type='text'
              id='email'
              value={formData.email}
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type='submit' loading={loading}>
              Create
            </Button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div>
        <div className='page-header'>
          <h1>{formData.email ?? 'Loading...'}</h1>
        </div>
        <form>
          <div className='form-row'>
            <div className='form-group'>
              <label htmlFor='name'>First Name</label>
              <input
                onChange={(e) =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
                placeholder='Name'
                type='text'
                id='name'
                value={formData.firstname}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='surname'>Surname</label>
              <input
                onChange={(e) =>
                  setFormData({ ...formData, surname: e.target.value })
                }
                placeholder='Surname'
                type='text'
                id='surname'
                value={formData.surname}
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              placeholder='Email'
              type='text'
              id='email'
              value={formData.email}
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            {/* <Button type='submit' loading={loading}>
              Create
            </Button> */}
          </div>
        </form>
      </div>
    );
  }
};

export default User;
