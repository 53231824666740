import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components';
import api, { routes } from '../../constants/api';

const Audience = () => {
  const { audienceId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    code: ''
  });

  const isNew = audienceId === 'new';

  const createAudience = async (e: any) => {
    e.preventDefault();
    const { data } = await api.post(routes.audiences.create, formData);

    if (data.success) {
      navigate(-1);
    }
  };

  const updateAudience = async (e: any) => {
    e.preventDefault();
    if (!audienceId) return;

    const { data } = await api.put(
      routes.audiences.update(parseInt(audienceId)),
      formData
    );

    if (data.success) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isNew) return;

    const fetchAudience = async () => {
      if (!audienceId) return;

      const { data } = await api.get(
        routes.audiences.get(parseInt(audienceId))
      );
      setFormData(data.data);
    };

    fetchAudience();
  }, [audienceId]);

  if (isNew) {
    return (
      <div>
        <div className='page-header'>
          <h1>New Audience</h1>
        </div>
        <form onSubmit={createAudience}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Audience'
              type='text'
              id='name'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='name'>Code</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, code: e.target.value })
              }
              placeholder='CODE'
              type='text'
              id='code'
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type='submit'>Create</Button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div>
        <div className='page-header'>
          <h1>New Audience</h1>
        </div>
        <form onSubmit={updateAudience}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder='Audience'
              type='text'
              id='name'
              value={formData.name}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='name'>Code</label>
            <input
              onChange={(e) =>
                setFormData({ ...formData, code: e.target.value })
              }
              placeholder='CODE'
              type='text'
              id='code'
              value={formData.code}
            />
          </div>
          <div className='form-footer'>
            <Button secondary onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type='submit'>Update</Button>
          </div>
        </form>
      </div>
    );
  }
};

export default Audience;
