import axios from 'axios';

export const apiUrl = '/api/v1';

const api = axios.create({
  baseURL: apiUrl
});

export const routes = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    authenticate: '/auth/authenticate',
    activate: 'auth/create-account',
    forgotPassword: '/auth/forgot-password'
  },
  journeys: {
    list: '/journeys',
    create: '/journeys',
    get: (id: number) => `/journeys/${id}`,
    update: (id: number) => `/journeys/${id}`,
    delete: (id: number) => `/journeys/${id}`
  },
  campaigns: {
    list: '/campaigns',
    create: '/campaigns',
    get: (id: number) => `/campaigns/${id}`,
    update: (id: number) => `/campaigns/${id}`,
    delete: (id: number) => `/campaigns/${id}`
  },
  emails: {
    list: '/emails',
    create: '/emails',
    get: (id: number) => `/emails/${id}`,
    update: (id: number) => `/emails/${id}`,
    delete: (id: number) => `/emails/${id}`
  },
  audiences: {
    list: '/audiences',
    create: '/audiences',
    get: (id: number) => `/audiences/${id}`,
    update: (id: number) => `/audiences/${id}`,
    delete: (id: number) => `/audiences/${id}`
  },
  users: {
    list: '/users',
    create: '/users',
    get: (id: number) => `/users/${id}`,
    update: (id: number) => `/users/${id}`,
    delete: (id: number) => `/users/${id}`
  }
};

export default api;
