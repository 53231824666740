import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, RichInput } from '../../components';
import api, { routes } from '../../constants/api';
import useData, { IEmail } from '../../hooks/useData';
import './_styles.emails.scss';

const fields = [
  `@firstname`,
  '@lastname',
  '@email',
  '@daysLeft',
  '@points',
  '@coachName',
  '@coachEmail',
  '@dueDate'
];

interface EmailRequest extends IEmail {
  defaultBody: string;
  hasSet?: boolean;
}

const Email = () => {
  const { campaignId, journeyId, emailId } = useParams();
  const isNew = emailId === 'new';
  const { activeCampaign, activeEmail, activeJourney } = useData();
  const [formData, setFormData] = useState<EmailRequest>({
    ...activeEmail,
    defaultBody: activeEmail.body
  });
  const navigate = useNavigate();
  const [actionEvents, setActionEvents] = useState({
    loading: false,
    success: false
  });

  const saveEmail = async () => {
    setActionEvents({ loading: true, success: false });
    if (isNew) {
      const { data } = await api.post(routes.emails.create, {
        ...formData,
        campaign_id: activeCampaign.id
      });
      navigate(-1);
    } else {
      const { data } = await api.put(
        routes.emails.update(activeEmail.id),
        formData
      );
    }
    setActionEvents({ loading: false, success: true });
    setTimeout(() => {
      setActionEvents({ loading: false, success: false });
    }, 2000);
  };

  const onInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (activeEmail.id > 0 || isNew) {
      setFormData({
        ...activeEmail,
        defaultBody: activeEmail.body,
        hasSet: true
      });
    }
  }, [activeEmail]);

  return (
    <div className='page-body'>
      <div className='page-header tile'>
        <div>
          <h2>{journeyId}</h2>
          <p>
            Campaign: <strong>{campaignId}</strong>
          </p>
          <p>
            Audience: <strong>{activeJourney?.audience.code}</strong>
          </p>
          <p>
            Email: <strong>{activeEmail.name}</strong>
          </p>
        </div>
        <Button
          onClick={saveEmail}
          success={actionEvents.success}
          loading={actionEvents.loading}
          loadingText='Saving...'
          successText='Saved!'
        >
          {isNew ? 'Create' : 'Save'}
        </Button>
      </div>
      {isNew && (
        <div>
          <h3>Name</h3>
          <input onChange={onInputChange} placeholder='Email' name='name' />
        </div>
      )}
      <div>
        <h3>From</h3>
        <div className='from-row'>
          <input onChange={onInputChange} name='from' value={formData.from} />
          <p>Or</p>
          <div
            className={formData.from === '@coachEmail' ? 'active' : ''}
            onClick={() => setFormData({ ...formData, from: '@coachEmail' })}
          >
            <p>Use Coach Email</p>
          </div>
        </div>
      </div>
      <div>
        <h3>Subject</h3>
        <input
          onChange={onInputChange}
          name='subject'
          value={formData.subject}
        />
      </div>
      <div>
        <h3>Body</h3>
        <div className='email-editor'>
          {(activeEmail.id > 0 || isNew) && (
            <RichInput
              keys={fields}
              onChange={(value) => {
                if (formData.hasSet) {
                  setFormData({ ...formData, body: value });
                }
              }}
              defaultValue={formData.defaultBody}
            />
          )}
          <div className='tile'>
            <h4>Fields</h4>
            {fields.map((field, i) => (
              <p
                className={
                  formData.body.includes(field) ? 'field used' : 'field'
                }
                key={i}
              >
                {field}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className='schedule'>
        <h3>Schedule</h3>
        <div className='tile'>
          <p>Send Every</p>
          <input
            type='number'
            placeholder='0'
            value={formData.runRange}
            onChange={onInputChange}
            name='runRange'
            min={1}
          />
          <select
            value={formData.runEvery ?? 'days'}
            onChange={onInputChange}
            name='runEvery'
          >
            <option value='days'>Days</option>
            <option value='weeks'>Weeks</option>
            <option value='months'>Months</option>
          </select>
          <p>at</p>
          <select
            value={formData.runTime}
            name='runTime'
            onChange={onInputChange}
          >
            <option>01:00</option>
            <option>02:00</option>
            <option>03:00</option>
            <option>04:00</option>
            <option>05:00</option>
            <option>06:00</option>
            <option>07:00</option>
            <option>08:00</option>
            <option>09:00</option>
            <option>10:00</option>
            <option>11:00</option>
            <option>12:00</option>
          </select>
          <select
            value={formData.runTimePeriod}
            name='runTimePeriod'
            onChange={onInputChange}
          >
            <option>AM</option>
            <option>PM</option>
          </select>
        </div>
      </div>
      <div className='rules'>
        <h3>Rules</h3>
        {formData.rules.map((rule, i) => (
          <div className='tile'>
            <p>When</p>
            <select
              value={rule.when}
              name='runTimePeriod'
              onChange={(e) => {
                const rules = [...formData.rules];
                rules[i].when = e.target.value;
                setFormData({ ...formData, rules });
              }}
            >
              <option value='days_left'>Days Left</option>
              <option value='perf_stat'>Perf Stat</option>
              <option value='points'>Points</option>
            </select>
            <p>=</p>
            <input
              placeholder='Value'
              onChange={(e) => {
                const rules = formData.rules;
                rules[i].value = e.target.value;
                setFormData({ ...formData, rules });
              }}
              name='runRange'
              value={rule.value}
            />
            <button
              onClick={() => {
                setFormData({
                  ...formData,
                  rules: formData.rules.filter((r, j) => j !== i)
                });
              }}
            >
              X
            </button>
          </div>
        ))}
        <div
          className='add-rule'
          onClick={() => {
            setFormData({
              ...formData,
              rules: [...formData.rules, { when: 'days_left', value: '' }]
            });
          }}
        >
          <p>Add New Rule</p>
        </div>
      </div>
    </div>
  );
};

export default Email;
