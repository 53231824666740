import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api, { routes } from '../../constants/api';

export interface cardsState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  journeys: IJourney[];
}

export interface IJourney {
  id: number;
  name: string;
  description: string;
  audience: string;
}

const initialState: cardsState = {
  journeys: [],
  status: 'idle'
};

export const getJourneys = createAsyncThunk('journeys/get', async () => {
  const response = await api.get(routes.journeys.list);
  return response.data;
});

const journeySlice = createSlice({
  name: 'journeys',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJourneys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getJourneys.fulfilled, (state, action) => {
        state.status = 'success';
        state.journeys = action.payload.data;
      });
  }
});

export default journeySlice.reducer;
